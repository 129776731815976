export const ErrorCode = {
  0:"Thành công",
  1001:"Tài khoản không đủ tiền",
  1002:"Giao dịch đã khôi phục",
  1003:"Quá hạn mức số dư",
  1004:"Quá hạn mức giao dịch",
  1005:"Yêu cầu quá hạn",
  1006:"Lỗi hệ thống",
  1007:"Lỗi hệ thống (db)",
  1008:"Sai tham số",
  1009:"Dữ liệu bị trùng",
  1010:"Không tìm thấy dữ liệu",
  1011:"Chưa thiết lập password",
  1012:"Quá số lần sai mật khẩu",
  1013:"Mật khẩu hết hạn",
  1014:"Sai mật khẩu",
  1015:"Trùng mật khẩu cũ",
  1016:"Mật khẩu đã dùng trước đó",
  1020:"Dữ liệu bắt buôc không có",
  1021:"Tham số quá ngắn",
  1022:"Tham số quá dài",
  1023:"Tham số không đúng",
  1024:"Xem lại hệ thống",
  1025:"Rule đã bị khóa",
  2001: "Hệ thống đang nghẽn",
  2002: "Lỗi nhà mạng",
  2003: "Thuê bao trả sau",
  2004: "Lỗi nhà mạng",
  2005: "Giao dịch đang xử lý phía nhà mạng",
  2006: "Thuê bao trả trước",
  2007: "Giao dịch xử lý thất bại phía nhà mạng",//khong co ket qua
  2008: "Lỗi thuê bao mới kích hoạt",
  2009: "Lỗi thuê bao đã bị khóa",
  2010: "Số tiền thanh toán chưa đúng với số tiền trên hóa đơn nhà mạng",
  2011: "Hiện các giao dịch phía nhà mạng đang bị nghẽn, vui lòng thử lại sau",
  2012: "Thuê bao nạp tiền không hợp lệ",
  2013: "Thuê bao không sử dụng được kênh nạp tiền này",
  2014: 'Giao dịch đã bị hủy',
  //for bank
  3000: 'Yêu cầu nhập OTP',
  3001: 'OTP hết hạn',
  3002: 'Sai OTP',
  3003: 'Sai OTP 3 lần',
  3010: 'Số tiền không hợp lệ',
  3011: 'Thẻ không tồn tại',
  3012: 'Thẻ liên kết đã tồn tại',
  3013: 'Thẻ chưa đăng ký dịch vụ giao dịch trực tuyến',
  3014: 'Không đủ số dư thực hiện giao dịch',
  3015: 'Giao dịch vượt hạn mức cho phép',
  //for card
  4000: 'Thẻ đã sử dụng',
  4001: 'Thẻ đã bị khóa',
  4002: 'Thẻ hết hạn sử dụng',
  4003: 'Thẻ chưa kích hoạt',
  4004: 'Mã thẻ và số Serial không khớp',
  4005: 'Thẻ thử quá số lần cho phép',
  4006: 'CardSerial không hợp lệ',
  4007: 'CardCode không hợp lệ',
  4008: 'Thẻ không tồn tại',
  4009: 'Không kiểm tra được tình trạng thẻ',
  4010: 'Thông tin thẻ không hợp lệ',
  4011: 'Tài khoản bị khóa do nhập sai thẻ nhiều lần',
  //for core
  1:"Sai thông số",
  3:"Tài khoản không tồn tại",
  4:"Tài khoản chưa đăng ký",
  5:"Tài khoản chưa kích hoạt",
  6:"Tài khoản tạm khóa",
  7:"Vi phạm chính sách",
  8:"Sai mật khẩu",
  9:"Mật khẩu quá hạn",
  10:"Mật khẩu đã dùng trước đó",
  11:"Sai Mật khẩu quá số lần quy định",
  12:"Trùng mật khẩu",
  13:"Lỗi dịch vụ(TARGET_NOT_FOUND)",
  14:"Lỗi dịch vụ chưa hỗ trợ(TARGET_NOT_REGISTERED)",
  15:"Lỗi dịch vụ(TARGET_INACTIVE)",
  16:"Lỗi dịch vụ(TARGET_SUSPENDED)",
  17:"Số tiền không đúng",
  18:"TK đã tồn tại",
  19:"AGENT_BLACKLISTED",
  20:"TARGET_BLACKLISTED",
  22:"TARGET_IS_SELF",
  23:"Số tiền quá nhỏ",
  24:"Số tiền quá lớn",
  25:"Sai số tiền",
  26:"TARGET_REQUIRED",
  27:"AGENT_EXPIRED",
  28:"TARGET_EXPIRED",
  29:"Sai mật khẩu",
  30:"AGENT_ACTIVE",
  31:"AGENT_NOT_PARTY",
  32:"Yêu cầu quá hạn",
  33:"Giao dịch reversal",
  34:"TRANSACTION_NOT_TWO_PARTY",
  35:"INVALID_STATUS_CHANGE",
  36:"Giao dịch quá cũ",
  37:"Giao dịch chưa hoàn tất",
  38:"Tài khoản ngừng sử dụng",
  39:"TARGET_STOPPED",
  40:"AGENT_STARTED",
  41:"Lỗi kết nối",
  42:"Sai số điện thoại",
  43:"Kết nối không tồn tại",
  44:"Sai voucher",
  45:"Kết nối timeount",
  46:"Số tiền ngoài ngưỡng cho phép",
  47:"Dịch vụ chưa hỗ trợ",
  48:"BILLPAY_ACCOUNT_INVALID",
  49:"BANK_OFFLINE",
  50:"BANK_ERROR",
  51:"SYNC_NOT_REQUIRED",
  52:"DEVICE_ALREADY_ACTIVE",
  53:"CONFIG_UPDATE_NOT_REQUIRED",
  54:"NOT_DEVICE_OWNER",
  55:"ALIAS_ALREADY_EXISTS",
  56:"ALIAS_NOT_FOUND",
  57:"AGENT_ALREADY_SUSPENDED",
  58:"AGENT_ALREADY_STOPPED",
  59:"CANNOT_USE_WALLET_TYPE",
  60:"SCHEDULE_DISALLOWED",
  61:"INVALID_SCHEDULE",
  62:"SCHEDULE_NOT_FOUND",
  63:"CONFIRM_MULTIPLE_TXNS",
  64:"AGENT_IS_NOT_SPECIAL",
  66:"CONNECTION_OFFLINE",
  67:"CONNECTION_ERROR",
  68:"OPERATION_NOT_SUPPORTED",
  69:"AGENT_NOT_WELL_CONFIGURED",
  70:"AGENT_HAS_TRANSACTIONS",
  71:"Tài khoản đã bị xóa",
  72:"Tài khoản dến đã bị xóa",
  73:"AGENT_HAS_BALANCE",
  74:"INVALID_PASSWORD_MISMATCH",
  75:"REVERSAL_FAILED",
  76:"Trùng tên nhóm",
  77:"GROUP_NOT_EMPTY",
  78:"CONFIRM_SAME_INITIATOR",
  103:"Giao dịch thất bại",
  104:"Mệnh giá chưa hỗ trợ",
  250:"CONFIRM_TRANSFER",
  251:"CONFIRM_CASHOUT",
  252:"CONFIRM_SELL",
  253:"CONFIRM_BILLPAY",
  255:"CONFIRM_LINK",
  256:"CONFIRM_JOIN_PARENT",
  257:"CONFIRM_JOIN_CHILD",
  312:"MODIFY_WALLET_OK",
  400:"Giao dịch không tồn tại",
  401:"TRANSFER_CONFIRM_REJECTED",
  415:"TRACE_TRANSACTION_NOT_FOUND",
  423:"Giao dịch đã kết thúc",
  425:"MESSAGE_TOO_LONG",
  501:"CANNOT_LINK_TO_SUBAGENT",
  502:"CANNOT_LINK_TO_SELF",
  503:"LINK_AGENT_DELETED",
  504:"CANNOT_JOIN_TO_SELF",
  505:"AGENTS_ALREADY_JOINED",
  506:"JOIN_AGENT_DELETED",
  507:"AGENTS_NOT_JOINED",
  508:"PURCHASE_ORDER_NUMBER_NOT_UNIQUE",
  509:"REBATE_AMOUNT_NOT_EXACT",
  510:"REBATE_AMOUNT_NOT_MULTIPLE",
  511:"REBATE_AMOUNT_TOO_SMALL",
  520:"VOUCHER_RECIPIENT_NOT_FOUND",
  521:"VOUCHER_EXPIRY_DATE_PASSED",
  522:"VOUCHER_NOT_EXIST",
  523:"NONDELETED_VOUCHERID_PROVIDERID_EXISTS",
  524:"VOUCHER_NOT_DELETABLE_STATE",
  525:"MATCHING_VOUCHER_NOT_FOUND"
}
